import React from 'react'
import Table from '../../Component/Table/Table';
import { ModalComponent } from '../../Component';
import { ModalDelete, ModalEditUser } from '../../Organism';

function TemplateUsers(props) {
    return (
        <div>
            <Table
                columns={props.tableColumns}
                data={props.tableData}
                onSelectionChange={props.handleSelectionChange}
                onEdit={props.onEdit}
                isPagination={true}
                currentPage={props.currentPage}
                handlePageChange={props.handlePageChange}
                totalPages={props.TotalDoc}
                handleSort={props.handleSort}
                searchWord={props.searchWord}
                onChaneSearch={props.onChaneSearch}
            />
            {props.modalIsOpen && (
                <ModalComponent
                    contentStyle={"delete-modal"}
                >
                    <ModalDelete
                        closeModal={props.closeModal}
                        action={props.deleteUsers}
                    />
                </ModalComponent>

            )}
            {props.modalEditIsOpen && (
                <ModalComponent
                    contentStyle={"edit-user-modal"}
                >
                    <ModalEditUser
                        closeModal={props.setModalEditIsOpen}
                        action={props.updateUserInfo}
                        form={props.formUser}
                        onChange={props.onChange}
                    />
                </ModalComponent>

            )}
        </div>
    )
}

export default TemplateUsers