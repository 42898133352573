import { createSlice } from '@reduxjs/toolkit';


const initiaState = {
  lang:"fr"
};

const SettingReducer = createSlice({
  name: 'setting',
  initialState: initiaState,
  reducers: {
    setLang: (state, action) => {
      state.lang = action.payload
    },
  }
});

export const { setLang } = SettingReducer.actions;

export default SettingReducer.reducer;
