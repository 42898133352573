import React, { useContext, useEffect, useState } from 'react'
import Header from '../../Component/Header/Header'
import { LangContext } from '../../Lang'
import { TemplateUsers } from "../../Template"
import { Circular, Icon } from '../../Component'
import { getUsersApi, updateUserApi } from '../../Api/Users'
import { PER_PAGE, createState } from '../../Common'
import { formValidation } from '../../Common/Functions/Validation'
import { useSelector } from 'react-redux'

function PageUsers() {
    const lang = useContext(LangContext)
    const { user, role } = useSelector(state => state.auth)
    console.log("user", user);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [TotalDoc, setTotalDoc] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);
    const [users, setUsers] = useState([])
    const [searchWord, setSearchWord] = useState("")
    const [sort, setSort] = useState({ field: "", order: 1 })
    const [deletedUser, setDeletedUser] = useState([])
    const [modalEditIsOpen, setModalEditIsOpen] = useState(false)
    const [updatedUser, setUpdatedUser] = useState("")
    const initialForm = {
        email: { ...createState({ value: "" }) },
        lastName: { ...createState({ value: "" }) },
        firstName: { ...createState({ value: "" }) },
        phone: { ...createState({ value: "" }) },
    }
    const [form, setForm] = useState(initialForm)
    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }
    const tableColumns = [
        { key: 'selection', title: 'Select' },
        { key: 'lastName', title: lang.lastName },
        { key: 'firstName', title: lang.firstName },
        { key: 'email', title: lang.email },
        { key: 'phone', title: lang.phone },
        {
            key: 'active',
            title: lang.active,
            render: (rowData) => <CustomActiveComponent data={rowData} />,
        },
        {
            key: 'action',
            title: lang.action,
            render: (rowData) => <CustomActiveComponent data={rowData.active} />,
        },
    ];
    const transformData = (data) => {
        const newData = data?.map((item) => {
            return (
                {
                    id: item._id,
                    email: item.email,
                    lastName: item.lastName,
                    firstName: item.firstName,
                    phone: item.phone,
                    isArchived: item.isArchived,
                }
            )
        })
        return newData
    }
    const fetchUsers = async () => {
        const resp = await getUsersApi(currentPage, PER_PAGE, searchWord, sort.field, sort.order, user.role === "admin" ? user?.siteReference : "")
        if (resp.statusCode === 200) {
            const newData = transformData(resp?.data[0]?.data)
            setUsers(newData)
            setTotalDoc(resp?.data[0].metadata[0].total)
        }
    }
    useEffect(() => {
        fetchUsers()
    }, [currentPage])
    const activeUser = async (id, active) => {
        const body = {
            isArchived: active
        }
        const resp = await updateUserApi(id, body)
        if (resp.statusCode === 200) {
            const copyUsers = users
            const updatedItems = copyUsers.map(item => {
                if (item.id === id) {
                    return { ...item, isArchived: active };
                }
                return item;
            });

            setUsers(updatedItems);
        }
    }
    const CustomActiveComponent = ({ data }) => {
        if (data.isArchived === false) {
            return (
                <div className='flex'>
                    <Circular width={32} height={32}>
                        <Icon onClick={() => { activeUser(data.id, true) }} iconName={"check"} color={"#00BE86"} size={20} />
                    </Circular>
                </div>
            )
        }
        return (
            <div className='flex'>
                <Circular width={32} height={32} >
                    <Icon onClick={() => { activeUser(data.id, false) }} iconName={"Power"} color={"#F12727"} size={20} />
                </Circular>
            </div>
        )
    };
    const onEdit = (index) => {
        const userData = users.filter((item) => item.id === index);
        setUpdatedUser(index)
        setForm({
            email: { ...form["email"], value: userData[0].email, isInvalid: false },
            phone: { ...form["phone"], value: userData[0].phone, isInvalid: false },
            firstName: { ...form["firstName"], value: userData[0].firstName, isInvalid: false },
            lastName: { ...form["lastName"], value: userData[0].lastName, isInvalid: false },
        })
        setModalEditIsOpen(true)
    }
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    const handleSelectionChange = async (selectedRows) => {
        openModal()
        setDeletedUser(selectedRows)
    };
    const deleteUsers = async () => {
        let allApisSuccessful = true;
        for (let index = 0; index < deletedUser.length; index++) {
            const body = {
                isDeletedFromAdmin: true
            }
            const id = deletedUser[index];
            const response = await updateUserApi(id, body)
            if (response.statusCode !== 200) {
                allApisSuccessful = false;
                break;
            }
        }
        if (allApisSuccessful) {
            await fetchUsers();
            closeModal()
        }
    }
    const handleSort = async (field, order) => {
        setSort({ field: field, order: order })
        const resp = await getUsersApi(currentPage, PER_PAGE, searchWord, field, order)
        if (resp.statusCode === 200) {
            const newData = transformData(resp?.data[0]?.data)
            setUsers(newData)
            setTotalDoc(resp?.data[0].metadata[0].total)
        }
    }
    const onChaneSearch = async (value) => {
        setSearchWord(value)
        const resp = await getUsersApi(1, PER_PAGE, value, sort.field, sort.order)
        if (resp.statusCode === 200) {
            const newData = transformData(resp?.data[0]?.data)
            setUsers(newData)
            setTotalDoc(resp?.data[0].metadata[0].total)
        }
    }
    const onChange = (e, key) => {
        setForm({ ...form, [key]: { ...form[key], value: e.target.value.trim(), isInvalid: false } })
    }
    const updateUserInfo = async () => {
        const validation = [
            {
                value: 'email',
                validation: [
                    { error: lang.errorEmail, type: 'isNotEmpty' },
                    { error: lang.msgInvalid, type: 'isMail' },
                ]
            },
            {
                value: 'firstName',
                validation: [
                    { error: lang.errorEmail, type: 'isNotEmpty' },
                ]
            },
            {
                value: 'lastName',
                validation: [
                    { error: lang.errorEmail, type: 'isNotEmpty' },
                ]
            },
            {
                value: 'phone',
                validation: [
                    { error: lang.errorEmail, type: 'isValidPhoneNumber' },
                    { error: lang.errorEmail, type: 'isNotEmpty' },
                ]
            },

        ];
        const { res, verif } = formValidation({ list: validation, state: form });
        if (!verif) {
            setForm({ ...form, ...res })
            return
        }
        const body = {
            email: form.email.value,
            firstName: form.firstName.value,
            lastName: form.lastName.value,
            phone: form.phone.value,
        }
        const response = await updateUserApi(updatedUser, body)
        if (response.statusCode === 200) {
            setModalEditIsOpen(false)
            fetchUsers()
        }
    }
    return (
        <div>
            <Header
                title={lang.usersMin}
            />
            <div className='w-100'>
                <TemplateUsers
                    tableColumns={tableColumns}
                    tableData={users}
                    onEdit={onEdit}
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    handleSelectionChange={handleSelectionChange}
                    TotalDoc={TotalDoc / PER_PAGE}
                    handleSort={handleSort}
                    searchWord={searchWord}
                    onChaneSearch={onChaneSearch}
                    modalIsOpen={modalIsOpen}
                    closeModal={closeModal}
                    deleteUsers={deleteUsers}
                    modalEditIsOpen={modalEditIsOpen}
                    setModalEditIsOpen={setModalEditIsOpen}
                    formUser={form}
                    onChange={onChange}
                    updateUserInfo={updateUserInfo}
                />
            </div>
        </div>
    )
}

export default PageUsers