
import { Outlet } from "react-router-dom";
import SideBar from "../../Component/SideBar/SideBar";

const PublicRoute = () => {
    return (
        <div className="content-page">
            <Outlet />
        </div>
    )
}

export default PublicRoute