import React, { useContext } from 'react'
import AtomText from '../../Component/Text/Text'
import { Button } from '../../Component'
import { LangContext } from '../../Lang'

function ModalDelete(props) {
    const lang = useContext(LangContext)
    return (
        <div className='w-100 h-100 flex flex-col justify-space'>
            <AtomText type={"type-3"}>{lang.deleteMsg}</AtomText>
            <div className='flex justify-space items-center'>
                <Button
                    type={"cancel-delete"}
                    onClick={props.closeModal}
                    value={
                        <AtomText type={"type-7"}>
                            {lang.cancel}
                        </AtomText>
                    }
                />
                <Button
                    type={"delete-users"}
                    onClick={props.action}
                    value={
                        <AtomText type={"type-6"}>
                            {lang.delete}
                        </AtomText>
                    }
                />
            </div>
        </div>
    )
}

export default ModalDelete