
import { LangContext } from "../../Lang"
import { useSelector } from "react-redux"
import EN from "../../Lang/En/en"
import AR from "../../Lang/Ar/ar"
import FR from "../../Lang/Fr/fr"
export default function LangProvider(props){
  const lang = useSelector((state) => state.setting.lang)
  let type = FR
  if (lang === "en") {
    type = EN
  } else if (lang==="ar") {
    type = AR
  }
  return <LangContext.Provider value={type}>{props.children}</LangContext.Provider>
}
