import React from 'react'

function ModalComponent(props) {
    return (
        <div className={"popup " + props.popupStyle}>
            <div className={"popup-content " + props.contentStyle}>
                {props.children}
            </div>
        </div>
    )
}

export default ModalComponent