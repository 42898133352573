import React, { useContext } from 'react'
import { Button, Input } from '../../Component'
import AtomText from '../../Component/Text/Text'
import { LangContext } from '../../Lang'

function ModalEditUser(props) {
    const lang = useContext(LangContext)
    return (
        <div className='w-100 h-100 flex flex-col justify-space'>
            <div>
                <Input
                    label={lang.email}
                    type={"primary"}
                    onChange={(e) => { props.onChange(e, "email") }}
                    value={props.form?.email?.value}
                    error={props.form?.email?.errorMessage}
                    isInvalid={props.form.email?.isInvalid}
                />
                <Input
                    label={lang.lastName}
                    type={"primary"}
                    onChange={(e) => { props.onChange(e, "lastName") }}
                    value={props.form?.lastName?.value}
                    error={props.form?.lastName?.errorMessage}
                    isInvalid={props.form.lastName?.isInvalid}
                />
                <Input
                    label={lang.firstName}
                    type={"primary"}
                    onChange={(e) => { props.onChange(e, "firstName") }}
                    value={props.form?.firstName?.value}
                    error={props.form?.firstName?.errorMessage}
                    isInvalid={props.form?.firstName?.isInvalid}
                />
                <Input
                    label={lang.phone}
                    type={"primary"}
                    onChange={(e) => { props.onChange(e, "phone") }}
                    value={props.form?.phone?.value}
                    error={props.form?.phone?.errorMessage}
                    isInvalid={props.form?.phone?.isInvalid}
                />
            </div>
            <div className='flex justify-space items-center'>
                <Button
                    type={"cancel-delete"}
                    onClick={() => props.closeModal(false)}
                    value={
                        <AtomText type={"type-7"}>
                            {lang.cancel}
                        </AtomText>
                    }
                />
                <Button
                    type={"delete-users"}
                    onClick={props.action}
                    value={
                        <AtomText type={"type-6"}>
                            {lang.update}
                        </AtomText>
                    }
                />
            </div>
        </div>
    )
}

export default ModalEditUser