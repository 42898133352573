import { BrowserRouter, Route, Routes } from "react-router-dom"
import PublicRouteRender from "./PublicRouteRender/PublicRouteRender"
import PublicRoute from "./PublicRoute/PublicRoute"
import PrivateRouteRender from "./PrivateRouteRender/PrivateRouteRender"
import PrivateRoute from "./PrivateRoute/PrivateRoute"
import { ForgetPassword, Login, ResetPassword, PageUsers } from "../Pages"
import ResetRouteRender from "./ResetRouteRender/ResetRouteRender"
import LangProvider from "../Providers/LangProvider/LangProvider"
import ConfirmEmail from "../Pages/ConfirmEmail/ConfirmEmail"


function AppRoute() {
    return (
        <LangProvider>
            <BrowserRouter>
                <Routes>
                    <Route
                        element={
                            <PublicRouteRender>
                                <PublicRoute />
                            </PublicRouteRender>
                        }
                    >
                        <Route path="/forget" element={<ForgetPassword />} />
                        <Route path="/login" element={<Login />} />
                    </Route>

                    <Route
                        element={
                            <PrivateRouteRender>
                                <PrivateRoute />
                            </PrivateRouteRender>
                        }
                    >
                        <Route path="/" element={<PageUsers name="Users" />} />
                    </Route>
                    <Route
                        element={
                            <ResetRouteRender>
                                <PublicRoute />
                            </ResetRouteRender>
                        }
                    >
                        <Route path="/reset" element={<ResetPassword />} />
                        <Route path="/confirm" element={<ConfirmEmail />} />
                    </Route>

                </Routes>
            </BrowserRouter>
        </LangProvider>
    )
}

export default AppRoute