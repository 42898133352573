import React, { useContext, useEffect, useState } from 'react'
import { verifConfirmEmailTokenApi } from '../../Api/Auth'
import { Rings } from 'react-loader-spinner'
import ModalComponent from '../../Component/Modal/Modal'
import { LangContext } from '../../Lang'
import AtomText from '../../Component/Text/Text'


export default function ConfirmEmail() {
  const lang = useContext(LangContext)
  const [isLoading, setIsLoading] = useState(false)
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const [isNotAuthorized, setIsNotAuthorized] = useState(false)
  const verifToken = async () => {
    setIsLoading(true)
    const resp = await verifConfirmEmailTokenApi()
    if (resp && resp?.statusCode === 200) {
      setIsEmailVerified(true)
    } else {
      setIsNotAuthorized(true)
    }
    setIsLoading(false)
  }
  useEffect(() => {

    verifToken()
  }, [])
  return (
    <div className='flex justify-center items-center w-100 h-100'>
      {isLoading &&
        <Rings
          height="200"
          width="200"
          color="#7A00E6"
          radius="6"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="rings-loading"
        />}
      {(isEmailVerified || isNotAuthorized) &&
        <ModalComponent popupStyle={"bg-white "} contentStyle={"flex justify-center"}>
          <div className="flex box-shadow1 p-40 w-70" >
            <AtomText type={'type-7'} textStyle={isNotAuthorized && "color-red"} >
              {isEmailVerified && lang.mailConfirmationMessage}
              {isNotAuthorized && lang.errorConfirmationMail}
            </AtomText>

          </div>

        </ModalComponent>
      }

    </div>
  )
}
