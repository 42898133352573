import { useSelector } from "react-redux"
import { Navigate, } from "react-router-dom"

function PublicRouteRender(props) {
    const auth = useSelector((state) => state?.auth)
    return auth?.accessToken && auth?.refreshToken && auth?.user ? (
        <Navigate to="/" replace />
    ) : (
        props.children
    )
}
export default PublicRouteRender