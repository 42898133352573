
const lang = {
    password: "Mot de passe",
    newPassword: "Nouveau mot de passe",
    email: "Adresse mail",
    connexion: "Se Connecter",
    forgetPassword: "mot de passe oublié?",
    sendPassword: "Envoyer Mot de Passe",
    resetPassword: "valider",
    users: "UTILISATEURS",
    usersMin: "Utilisateurs",
    lastName: "Nom",
    firstName: "Prénom",
    email: "Email",
    phone: "Téléphone",
    active: "Active",
    action: "Action",
    errorEmail: "Le champ email est requis",
    errorLogin: "Vérifiez votre adresse e-mail et votre mot de passe",
    errorValidPassword: "Le champ mot de passe est requis et doit comporter au moins huit caractères, une lettre majuscule, un chiffre et un caractère spécial",
    msgInvalid: "Ce champ est mal renseigné, veuillez vérifier la conformité",
    msgErreurPassword1: "Le champ mot de passe est requis",
    msgErreurPassword2: "Le mot de passe est incorrect",
    resetPassword: "valider",
    confirmPassword: "Confirmation du mot de passe",
    onlyAdmins: "seul administrateur autorisé",
    forgetPassError: "non autorisé : utilisateur introuvable",
    unauthorized: "non autorisé",
    deleteMsg: "Êtes-vous sûr de vouloir supprimer les comptes sélectionnés ?",
    delete: "SUPPRIMER",
    cancel: "ANNULER",
    update: "Mettre à jour",
    logout: "Déconnexion",
    mailConfirmationMessage: "Votre compte a bien été activé. Rendez-vous sur l’application Dollycar et connectez-vous pour configurer vos trajets !",
    errorConfirmationMail: "oups, quelque chose s'est mal passé, nous n'avons pas pu confirmer votre compte"
}
export default lang;